import axios from 'axios'

const newRequests = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000/api/users'
      : 'https://ecommerceserver-7ucs.onrender.com/api/users',
  withCredentials: true,
})

// http://localhost:4000
// baseURL: "https://ecommerceserver-7ucs.onrender.com/api/users"

export default newRequests
